import React from 'react'

import Social_Facebook from '../../../static/Social_Facebook.svg'
import Social_Linkedin from '../../../static/Social_Linkedin.svg'
import Social_Instagram from '../../../static/Social_Instagram.svg'
import Social_Youtube from '../../../static/Social_Youtube.svg'
import Social_Twitter from '../../../static/Social_Twitter.svg'

import './SocialLinks.scss'

const SocialLinks = () => (
  <div className="social-links">
    <div className="wrapper">
      <h2>Stay Connected on Social Media</h2>
      <div className="links">
        <a className="social" target="_blank" href="https://www.facebook.com/ovationvr/">
          <Social_Facebook className="social-svg" />
        </a>
        <a className="social" target="_blank" href="https://www.linkedin.com/company/11261847/">
          <Social_Linkedin className="social-svg" />
        </a>
        <a className="social" target="_blank" href="https://www.instagram.com/ovationvr/">
          <Social_Instagram className="social-svg" />
        </a>
        <a className="social" target="_blank" href="https://www.youtube.com/channel/UC3OrW8taSo8kb2ZHxmfY_Ig">
          <Social_Youtube className="social-svg" />
        </a>
        <a className="social" target="_blank" href="https://twitter.com/ovationvr">
          <Social_Twitter className="social-svg" />
        </a>
      </div>
    </div>
  </div>
)

export default SocialLinks
